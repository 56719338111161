import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import createPersistedState from "vuex-persistedstate";

function getCartTotal(state) {
  let intTotal = 0;
  let arrBets = state.arrBets;
  for (let i = 0; i < arrBets.length; i++) {
    let objBet = arrBets[i];

    // let blnIsComplete = this.isBetComplete(objBet);
    // if (!blnIsComplete) {
    //   continue;
    // }
    let intNumDraws = parseInt(objBet.intNumDraws);
    let intStakeAmount = parseInt(objBet.intStakeAmount);

    //let objLottoConfig = this.getLottoConfig(objBet.strLottoId);
    //let { intLineCost } = objLottoConfig;
    let intLines = objBet.arrLines.length;
    intTotal += intLines * intStakeAmount * intNumDraws;
  }

  return intTotal;
}

function emitCartUpdate(state) {
  //console.log("emitting");

  //console.log("state is ", state);
  //console.log("carttotal is");

  let intCartTotal = getCartTotal(state);

  Vue.prototype.$utils.emitWCEvent2(Vue.prototype.$root, "cartUpdate", {
    intNumItems: state.arrBets.length,
    intCartTotal,
  });
}

function emitUserInfo(state) {
  let strBalance = "";
  let strPendingWithdrwal = "";
  let strMobile = "";
  let strAvatarUrl = "";
  let arrWalletBalances = [];

  if (state.objUserProfile) {
    strMobile = state.objUserProfile.mobile;
    strAvatarUrl = Vue.prototype.$utils.getAvatarFileName(state.objUserProfile);
  }

  if (state.objWalletStats) {
    strBalance = state.objWalletStats.fltEffectiveBalance;
  }
  if (state.arrWalletBalances) {
    arrWalletBalances = state.arrWalletBalances;
  }

  let objUserInfo = {
    strBalance,
    strMobile,
    strPendingWithdrwal,
    strAvatarUrl,
    arrWalletBalances,
  };

  console.log("emitting state from veux", objUserInfo);

  // this.$utils.emitWCEvent2(this, "userInfo", objUserInfo);
  Vue.prototype.$utils.emitWCEvent2(
    Vue.prototype.$root,
    "userInfo",
    objUserInfo
  );
}

function emitState(state) {
  emitUserInfo(state);
}

function getLottoModel(strLottoId) {
  let obj = {
    strLottoId: strLottoId,
    arrLines: [
      {
        arrNumbers: [],
      },
    ],
  };

  return obj;
}

export default new Vuex.Store({
  plugins: [createPersistedState()],

  state: {
    blnLoading: false,
    objBrandingSettings: {},
    arrLottos: [],
    arrLottoConfigs: [
      {
        strLottoId: "pick3",
        intPricePerLine: 2,
        strCurrency: "USD",
        intNumbersToPick: 3,
      },
    ],

    objSignInData: { objUser: {}, strToken: "" },
    objWalletStats: {},
    objUserProfile: {},
    objCredentials: {},
    intNumDraws: 1,
    intStakeAmount: 10,
    arrBets: [],
    objBet: null,
    arrWalletBalances: [],
  },
  getters: {
    objUserProfile: (state) => {
      try {
        return state.objUserProfile;
      } catch (e) {
        console.log(e);
      }
      return {};
    },
    arrLottoConfigs: (state) => {
      try {
        return state.arrLottoConfigs;
      } catch (e) {
        console.log(e);
      }
      return [];
    },
    arrLottos: (state) => {
      try {
        return state.arrLottos;
      } catch (e) {
        console.log(e);
      }
      return [];
    },

    getLottoConfig: (state) => (strLottoId) => {
      state.arrLottoConfigs = [
        {
          strLottoId: "pick3",
          intPricePerLine: 2,
          strCurrency: "USD",
          intNumbersToPick: 3,
        },
        {
          strLottoId: "pick4",
          intPricePerLine: 3,
          strCurrency: "USD",
          intNumbersToPick: 4,
        },
      ];

      let objLottoConfig = state.arrLottoConfigs.find(
        (obj) => obj.strLottoId == strLottoId
      );

      return objLottoConfig;
    },

    strToken: (state) => {
      try {
        return state.objSignInData.strToken;
      } catch (e) {
        console.log(e);
      }
      return "";
    },
    objUser: (state) => {
      try {
        return state.objUserProfile;
      } catch (e) {
        console.log(e);
      }
      return {};
    },
    blnIsUserLoggedIn: (state) => {
      let strToken = localStorage.getItem("t");

      if (strToken) {
        return true;
      }

      return false;
      try {
        if (state.objSignInData.objUser.player_id) {
          return true;
        }
      } catch (e) {
        console.log(e);
      }
      return false;
    },
    fltCartValue: (state) => {
      return getCartTotal(state);
    },
    objWalletStats: (state) => {
      try {
        return state.objWalletStats;
      } catch (e) {
        console.log(e);
      }
      return "";
    },
    arrWalletBalances: (state) => {
      try {
        return state.arrWalletBalances;
      } catch (e) {
        console.log(e);
      }
      return "";
    },
    intCartTotal: (state, getters) => {
      return getCartTotal(state);
    },
    objCredentials: (state) => {
      try {
        return state.objCredentials;
      } catch (e) {
        console.log(e);
      }
      return {};
    },
    arrBets: (state) => {
      try {
        return state.arrBets;
      } catch (e) {
        console.log(e);
      }
      return [];
    },
    objBet: (state) => {
      try {
        return state.objBet;
      } catch (e) {
        console.log(e);
      }
      return null;
    },
    arrCompletedBets: (state) => {
      try {
        //only return non pending
        let arr = [];
        let arrBets = state.arrBets;

        for (let i = 0; i < arrBets.length; i++) {
          let objBet = arrBets[i];
          objBet.intIndex = i;
          if (objBet.strStatus == "completed") {
            arr.push(objBet);
          }
        }
        return arr;
      } catch (e) {
        console.log(e);
      }
      return [];
    },
    objBrandingSettings: (state) => {
      return state.objBrandingSettings;
    },
  },

  mutations: {
    emitState(state) {
      emitCartUpdate(state);
      emitUserInfo(state);
    },
    resetState(state) {
      state.objSignInData = { objUser: {}, strToken: "" };
      emitCartUpdate(state);
    },
    signIn(state, objSignInData) {
      try {
        console.log(objSignInData);

        let { objUser, strToken } = objSignInData;

        state.objSignInData.objUser = objUser;
        state.objSignInData.strToken = strToken;
        emitState(state);
      } catch (e) {
        console.log(e);
      }
    },

    cleanupCart(state) {
      try {
        let arrNewBets = [];

        let arrBets = state.arrBets;
        for (let objBet of arrBets) {
          if (objBet.strStatus == "completed") {
            arrNewBets.push(objBet);
          }
        }
        state.arrBets = arrNewBets;
      } catch (e) {
        console.log(e);
      }
    },

    deleteLine(state, { intLineIndex }) {
      try {
        let objBet = state.objBet;
        let { arrLines } = objBet;
        arrLines.splice(intLineIndex, 1);
        emitCartUpdate(state);
      } catch (e) {
        console.log(e);
      }
    },

    deleteBet(state, intIndex) {
      try {
        state.arrBets.splice(intIndex, 1);
        emitCartUpdate(state);
      } catch (e) {
        console.log(e);
      }
    },

    setNumbers(state, { intLineIndex, intPoolIndex, arrNumbers }) {
      try {
        let objBet = state.objBet;
        let objLine = objBet.arrLines[intLineIndex];
        let objPool = objLine.arrPools[intPoolIndex];
        objPool.arrNumbers = arrNumbers;
      } catch (e) {
        console.log("pool number is", intPoolIndex);
        console.log(e);
      }
    },

    addBet(state, objBet) {
      try {
        console.log("commit bet", objBet);
        //status is added
        objBet.blnExpand = false;
        state.arrBets.push(objBet);
      } catch (e) {
        console.log(e);
      }
    },
    setBet(state, objBet) {
      try {
        console.log("set bet", objBet);
        //status is added
        objBet.blnExpand = false;
        state.objBet = objBet;
      } catch (e) {
        console.log(e);
      }
    },
    updateBet(state, { objBet }) {
      try {
        let arrLines = objBet.arrLines;

        // remove incomplete/status=="nok"
        objBet.arrLines = arrLines.filter(
          (objLine) => objLine.strStatus == "ok"
        );

        state.objBet = objBet;
      } catch (e) {
        console.log(e);
      }
      emitCartUpdate(state);
    },
    setBetStatus(state, { strStatus }) {
      try {
        state.objBet.strStatus = strStatus;
      } catch (e) {
        console.log(e);
      }
    },

    emptyCart(state) {
      try {
        state.objLottos = {};
        state.arrBets = [];
        state.objBet = null;
        emitCartUpdate(state);
        emitState(state);
      } catch (e) {
        console.log(e);
      }
    },
    logout(state) {
      try {
        // alert(state.objSignInData.strToken)

        localStorage.removeItem("t");
        state.objSignInData = { objUser: {} };
        state.objSignInData.strToken = "";
        // alert(state.objSignInData.strToken)
        state.arrBets = [];
        emitCartUpdate(state);
        emitState(state);
      } catch (e) {
        console.log(e);
      }
    },
    setWalletStats(state, objWalletStats) {
      try {
        state.objWalletStats = objWalletStats;
      } catch (e) {
        console.log(e);
      }
      emitState(state);
    },
    setWalletBalances(state, arrWalletBalances) {
      try {
        state.arrWalletBalances = arrWalletBalances;
      } catch (e) {
        console.log(e);
      }
      console.log("emitting from vue setWalletBalances");
      emitState(state);
    },
    setUserProfile(state, objUserProfile) {
      try {
        state.objUserProfile = objUserProfile;
      } catch (e) {
        console.log(e);
      }
      console.log("emitting from vue setUserProfile");

      emitState(state);
    },

    setCredentials(state, objCredentials) {
      try {
        state.objCredentials = objCredentials;
      } catch (e) {
        console.log(e);
      }
    },

    setLottoConfigs(state, arrLottoConfigs) {
      try {
        state.arrLottoConfigs = arrLottoConfigs;
      } catch (e) {
        console.log(e);
      }
    },
    setAvatar(state, strFileName) {
      try {
        state.objSignInData.objUser.avatar_filename = strFileName;
      } catch (e) {
        console.log(e);
      }
      emitState(state);
    },

    setLottos(state, arrLottos) {
      try {
        state.arrLottos = arrLottos;
      } catch (e) {
        console.log(e);
      }
    },

    setBrandingSettings(state, objBrandingSettings) {
      try {
        state.objBrandingSettings = objBrandingSettings;
      } catch (e) {
        console.log(e);
      }
    },
  },
  actions: {},
  modules: {},
});

import axios from "axios";

let strBaseUrl = "http://localhost:3000/api/";

if (process.env.VUE_APP_IMPREXIS_API_URL) {
  strBaseUrl = process.env.VUE_APP_IMPREXIS_API_URL;
  //console.log(process.env);
  //alert(process.env.VUE_APP_IMPREXIS_API_URL);
} else {
  strBaseUrl = "http://localhost:3000/api/";
  // strBaseUrl = "https://api.imprexisplatform.com/api/";
}

//strBaseUrl = "https://api.imprexisplatform.com/api/";
// strBaseUrl = "http://localhost:3000/api/";
// strBaseUrl = "https://api.imprexisplatform.com/api/";
// strBaseUrl = "https://apitest.imprexisplatform.com/api/";
// strBaseUrl = "https://api.imprexisplatform.com/api/";
strBaseUrl = "https://api.imprexisplatform2.com/api/";

let strBearerToken = null;
//import { accessToken } from "../composables/auth";
const axiosPlugin = {
  install(Vue, objOptions) {
    let { i18n, router, store } = objOptions;
    Vue.prototype.$axios = axios;

    // axios.interceptors.request.use((req) => {
    //   try {
    //     let strLocale = i18n.locale;
    //     req.params.locale = strLocale;
    //   } catch (e) {
    //     console.log(e);
    //   }
    //   return req;
    // });

    axios.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data

        return response.data;
      },
      function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
      }
    );

    const axiosInstance = axios.create({
      baseURL: strBaseUrl,
    });

    Vue.prototype.$myAxios = axiosInstance;
    Vue.prototype.setAxiosToken = (strToken) => {
      strBearerToken = strToken;
    };

    //axiosInstance.defaults.baseURL = process.env.VUE_APP_BASE;
    axiosInstance.defaults.headers["Content-Type"] = "application/json";
    axiosInstance.interceptors.request.use((req) => {
      if (strBearerToken) {
        req.headers["Authorization"] = `Bearer ${strBearerToken}`;
      }
      try {
        let strLocale = i18n.locale;
        if (!req.params) {
          req.params = {};
        }
        req.params.locale = strLocale;

        let { $strClientId, $strBrandingId } = Vue.prototype;

        req.params.c = $strClientId;
        req.params.b = $strBrandingId;
      } catch (e) {
        console.log(e);
      }
      return req;
    });

    axiosInstance.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data

        return response.data;
      },
      function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        console.log(error.config.url);
        let strUrl = error.config.url;

        //alert("logging out");
        let strStatus = error.response.status;
        if (strStatus == 401) {
          if (strUrl != "/u/ping") {
            objOptions.router.replace({ name: "LoggedOff" });
          }
        }
        let objResponse = { blnOK: false, strMessage: error.message };
        //return Promise.reject(error);
        return objResponse;
      }
    );
  },
};

export default axiosPlugin;
